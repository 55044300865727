import React , { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { addUserDevice , fetchDevices } from '../api/API'

function UserDevice() {
    let params = useParams()
    let history = useHistory()
    const [error ,setError] = useState([])
    const [deviceId , setDeviceId] = useState(0)
    const [devices , setDevices] = useState([])
    const [deviceNumber , setDeviceNumber] = useState('')
    useEffect(() => {
        const fetchDevicesAPI = async () => {
            await fetchDevices().then(res => setDevices(res.data.data))
        }
        fetchDevicesAPI()
    } , [])

    const handleCreateUserDevice = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        if(deviceId !== 0 )
        formData.append('device_id' , deviceId)
        if(deviceNumber !== '' )
        formData.append('device_number' , deviceNumber)
        await addUserDevice(params.id ,formData).then(res => history.goBack()).catch(e => setError(e.response.data.errors[0].message))
    }

    return (
      <div className="row">
      <div className="col-md-12">
          <div className="main-card mb-3 card">
              <div className="card-body">
                  <h5 className="card-title">Add User Device</h5>
                  <form id="signupForm" onSubmit={handleCreateUserDevice} className="col-md-10 mx-auto" method="post">
                      <div className="form-group">
                          <label for="device">Choose Device *</label>
                          <select className="form-control" onChange={(e) => setDeviceId(e.target.value)}>
                          <option value="0" key="0">Choose Device</option>
                              {
                                  devices.map((value , key) => (
                                    <option value={value.id} key={key}>{JSON.parse(value.name).en}</option>
                                  ))
                              }
                          </select>
                      </div>
                      <div className="form-group">
                          <label for="deviceNumber">Device Number *</label>
                          <div>
                              <input type="text" className="form-control" value={deviceNumber} onChange={e => setDeviceNumber(e.target.value)} placeholder="Enter Arabic Device Name"/>
                          </div>
                      </div>


                      {error.length !== 0 ? <div className="alert alert-danger">{error.length === 24 || error.length === 20 ? error : JSON.parse(error).en}</div> : ''}

                      <div className="form-group">
                          <button type="submit" className="btn btn-primary">Add Device To User
                          </button>
                      </div>
                  </form>
              </div>
          </div>
      </div>
  </div>
  )
}

export default UserDevice
