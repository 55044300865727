import React, { useState , useEffect} from 'react'
import { fetchIndustrialCities , deleteIndustrialCity } from '../api/API'
import {Link , useHistory, useParams} from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Pagination from '@material-ui/lab/Pagination'
import ModalIndustrialCity from '../components/ModalIndustrialCity'

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent : 'center'
      },
    },
  }));

function IndustrialCities() {
    const classes = useStyles()
    let history = useHistory()
    const [industrialCities , setIndustrialCities] = useState([])
    const [error , setError] = useState('')
    const [show , setShow] = useState(false)
    const [id , setId] = useState(null)
    const {page} = useParams()
    const [lang] = useState(localStorage.getItem("lang") || "en")

    useEffect(() => {
        fetchIndustrialCities(page).then(res => { setIndustrialCities(res.data); setError(''); }).catch(err => setError(err.response.data.message))
        setError('')
    } , [page])

    const fetchIndustrialCitiesAPI = async () => {
        await fetchIndustrialCities(page).then(res => { setIndustrialCities(res.data); setError(''); }).catch(err => setError(err.response.data.message))
    }

    const paginatePage = async (event , page) => {
        await fetchIndustrialCities(page).then(res => { setIndustrialCities(res.data); history.push(`/industrialCities/${page}`); setError('') }).catch(err => { setError(err.response.data.message); history.push(`/devices/${page}`) })
        setShow(false)
    }

   const handleDelete = async (id) => {
       await deleteIndustrialCity(id).then((res) => {
        fetchIndustrialCitiesAPI()
        setShow(false)
       })
       .catch(err => {})
   }


   const handleModal = (id) => {
       setId(id)
       setShow(true)
   }
    return (
        <>
        {
            show
            ?
            <ModalIndustrialCity id={id} rand={Math.random()} />
            :
            ''
        }
<div className="row" style={lang === "ar" ? {direction:'rtl' , textAlign : 'right'} : {direction:'ltr' , textAlign : 'left'}}>
        <div className="col-lg-12">
        { !error ? <div className="main-card mb-3 card">
            <div className="card-body"><h5 className="card-title">{lang === "en" ? "Industrial Cities List" : "قائمة المدن الصناعية"}</h5>
                <table className="mb-0 table table-striped">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>{lang === "en" ? "Name" : "الأسم"}</th>
                        <th>{lang === "en" ? "English Government Name" : "أسم المحافظة بالإنجليزية"}</th>
                        <th>{lang === "en" ? "Arabic Government Name" : "أسم المحافظة بالعربية"}</th>
                        <th>{lang === "en" ? "Edit" : "تعديل"}</th>
                        <th>{lang === "en" ? "Show" : "عرض"}</th>
                        <th>{lang === "en" ? "Delete" : "حذف"}</th>
                    </tr>
                    </thead>
                    <tbody>{

                        industrialCities.data != null ?
                        industrialCities.data.map (( value, key ) => {
                                return (<tr key={key}>
                                    <th scope="row">{key+=1}</th>
                                    <td>{lang === "en" ? JSON.parse(value.name).en : JSON.parse(value.name).ar}</td>
                                    <td>{JSON.parse(value.government.name).en}</td>
                                    <td>{JSON.parse(value.government.name).ar}</td>
                                    <td><Link to={`/industrialCity/${value.id}`}><i className="fas fa-edit"></i></Link></td>
                                    <td onClick={() => handleModal(value.id)}><Link to="#"><i className="fas fa-eye"></i></Link></td>
                                    <td><Link to="#" onClick={() => handleDelete(value.id)}><i className="fas fa-trash"></i></Link></td>
                                </tr>)
                            }) : ''
                     }
                     </tbody>
                </table>
            </div>
        </div> : <div className="alert alert-danger">{error}</div>
        
}
        <div className={classes.root}>
        <Pagination count={industrialCities.last_page} onChange={(event , page) => paginatePage(event , page)} color="secondary" />
        </div>
    </div>
</div>
      </>  
    )
}

export default IndustrialCities
