import Dashboard from './pages/Dashboard'
import Users from './pages/Users'
import AddUser from './pages/AddUser'
import EditUser from './pages/EditUser'
import ChangePassword from './pages/ChangePassword'
import EditProfile from './pages/EditProfile'
import Devices from './pages/Devices'
import AddDevice from './pages/AddDevice'
import EditDevice from './pages/EditDevice'
import userDevice from './pages/userDevice'
import Countries from './pages/Countries'
import AddCountry from './components/AddCountry'
import EditCountry from './pages/EditCountry'
import Governments from './pages/Governments'
import AddGovernment from './pages/AddGovernment'
import EditGovernment from './pages/EditGovernment'
import IndustrialCities from './pages/IndustrialCities'
import AddIndustrialCity from './pages/AddIndustrialCity'
import EditIndustrialCity from './pages/EditIndustrialCity'
import IndustrialZones from './pages/IndustrialZones'
import AddIndustrialZone from './pages/AddIndustrialZone'
import EditIndustrialZone from './pages/EditIndustrialZone'
import DataReads from './pages/DataReads'

const auth = localStorage.getItem("token") !== null ? true : false

const routes = [
    {
        path: "/",
        exact: true,
        auth:auth,
        component: Dashboard
    },
    {
        path: "/users/:page",
        auth:auth,
        component: Users
    },
    {
        path: "/user/add",
        auth:auth,
        component: AddUser
    },
    {
        path: "/user/:id",
        auth:auth,
        component: EditUser
    },
    {
        path: "/devices/:page",
        auth:auth,
        component: Devices
    },
    {
        path: "/device/add",
        auth:auth,
        component: AddDevice
    },
    {
        path: "/device/:id",
        auth:auth,
        component: EditDevice
    },
    {
        path: "/changePassword",
        auth:auth,
        component: ChangePassword
    },
    {
        path: "/editProfile",
        auth:auth,
        component: EditProfile
    },
    {
        path: "/userDevice/:id",
        auth:auth,
        component: userDevice
    },
    {
        path: "/countries/:page",
        auth:auth,
        component: Countries
    },
    {
        path: "/country/add",
        auth:auth,
        component: AddCountry
    },
    {
        path: "/country/:id",
        auth:auth,
        component: EditCountry
    },
    {
        path: "/governments/:page",
        auth:auth,
        component: Governments
    },
    {
        path: "/government/add",
        auth:auth,
        component: AddGovernment
    },
    {
        path: "/government/:id",
        auth:auth,
        component: EditGovernment
    },
    {
        path: "/industrialCities/:page",
        auth:auth,
        component: IndustrialCities
    },
    {
        path: "/industrialCity/add",
        auth:auth,
        component: AddIndustrialCity
    },
    {
        path: "/industrialCity/:id",
        auth:auth,
        component: EditIndustrialCity
    },
    {
        path: "/industrialZones/:page",
        auth:auth,
        component: IndustrialZones
    },
    {
        path: "/industrialZone/add",
        auth:auth,
        component: AddIndustrialZone
    },
    {
        path: "/industrialZone/:id",
        auth:auth,
        component: EditIndustrialZone
    },
    {
        path: "/dataReads/:user_device_id",
        auth:auth,
        component: DataReads
    }
  ];

  export default routes