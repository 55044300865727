import React , { useState , useEffect } from 'react'
import { login } from '../api/API'
import { Link } from 'react-router-dom'

function Login() {

    const [email , setEmail] = useState('')
    const [password , setPassword] = useState('')
    const [error ,setError] = useState([])
    const [lang , setLang] = useState('')

    useEffect(() => {
        setLang(localStorage.getItem("lang") || "en")
    } , [])

    const handleLogin = async (e) => {
        e.preventDefault()
        const data = new FormData()
        data.append('email' , email)
        data.append('password' , password)

        await login(data)
            .then( res => {
                if(res.data.user.authority === 0 || res.data.user.authority === 1){
                localStorage.setItem("token" , res.data.token.token)
                window.location = "/"
                }
                else{
                    setError('{"en" : "Unauthorized Access" , "ar" : "تسجيل دخول غير معروف"}')
                }
            })
            .catch( err => {
                setError(err.response.data.errors[0].message) 
            })
    }
    return (
        lang ?
        <div className="app-container app-theme-white body-tabs-shadow">
        <div className="app-container">
            <div className="h-100 bg-plum-plate bg-animation">
                <div className="d-flex h-100 justify-content-center align-items-center">
                    <div className="mx-auto app-login-box col-md-8">
                        <div className="mx-auto mb-3"></div>
                        <div className="modal-dialog w-100 mx-auto">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="h5 modal-title text-center">
                                        <h4 className="mt-2">
                                            <span>{lang === "en" ? "Please sign in to your account below." : "يرجى تسجيل الدخول إلى حسابك."}</span>
                                        </h4>
                                    </div>
                                    <form onSubmit={handleLogin}>
                                        <div className="form-row">
                                            <div className="col-md-12">
                                                <div className="position-relative form-group"><input style={lang === "ar" ? {textAlign:'right'} : {textAlign:'left'}} value={email} onChange={(e) => setEmail(e.target.value)} placeholder={lang === "en" ? "Enter email address" : "يرجى إدخال البريد الإلكترونى"} type="email" className="form-control" /></div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="position-relative form-group"><input style={lang === "ar" ? {textAlign:'right'} : {textAlign:'left'}} value={password} onChange={(e) => setPassword(e.target.value)} placeholder={lang === "en" ? "Enter password" : "يرجى إدخال كلمة المرور"} type="password" className="form-control" /></div>
                                            </div>
                                        </div>
                                        <div className="divider"></div>
                                    <h6 style={lang === "ar" ? {float:'right'} : {float:'left'}} className="mb-0">{lang === "en" ? "No account? " : "لا يوجد حساب؟ "}<Link to="/register" className="text-primary">{lang === "en" ? "Sign up now" : "سجل حسابك الأن"}</Link></h6>
                           <br></br><br></br>
                           {error.length !== 0 ? <div className="alert alert-danger" style={lang === "ar" ? {textAlign:'right'} : {textAlign:'left'}}>{error.length === 24 ? error : lang === "en" ? JSON.parse(error).en : JSON.parse(error).ar}</div> : <div></div>}
                               <br></br>
                                        <div className="modal-footer clearfix" style={lang === "ar" ? {float:'left'} : {float:'right'}}>
                                    <div className="float-right">
                                        <input type="submit" className="btn btn-primary btn-lg" value={lang === "en" ? "Login to Dashboard" : "تسجيل دخول"} />
                                    </div>
                                </div>
                                    </form>
                                     </div>
                                
                            </div>
                        </div>
                        <div className="text-center text-white opacity-8 mt-3">{lang === "en" ?"Copyright © Qodeex 2021" : "حقوق الملكية © كودكس للبرمجيات والتدريب 2021" }</div>
                    </div>
                </div>
            </div>
        </div>
</div> : <div></div>
    )
}

export default Login
