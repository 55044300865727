import React , { useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'
import Sidebar from './components/Sidebar'
import {getAccount} from './api/API'

function PrivateRoute({ authed, component: Component , ...rest }) {
    useEffect(() => {
        getAccount().then(res => {}).catch(err => window.location = "/login")
    }, [])
    return (
        <Route { ...rest } 
            render = {
                (props) => authed ? 
                <div className="app-container app-theme-gray">
                <div className="app-main">
                            <Sidebar />
                            <div className="app-sidebar-overlay d-none animated fadeIn"></div>
            <div className="app-main__outer">
                <div className="app-main__inner">
              
                    <div className="app-inner-layout app-inner-layout-page">
                      <div className="app-inner-layout__wrapper">
                            
                            <div className="app-inner-layout__content">
                                <div className="tab-content">
                                    <div className="container-fluid">
                        <Component {...props} /> 
                        </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
                                    
                </div>
            </div>
                   
                : <Redirect from="*" to='/login' />
            }/>
    )
}

export default PrivateRoute
