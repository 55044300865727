import React , { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { fetchAllCountries ,  showGovernment, updateGovernment } from '../api/API'
// import EditUser from '../pages/EditUser'

function EditGovernment(props) {
    const [country , setCountry] = useState({})
    let history = useHistory()
    const [countries , setCountries] = useState([])
    const [name , setName] = useState({ en : '' , ar : '' })
    const [countryId , setCountryId] = useState(0)
    const [error ,setError] = useState('')
    const [lang] = useState(localStorage.getItem("lang") || "en")

    useEffect(() => {
        showGovernment(props.id).then(res => {
            setCountry(res.data)
            setName({ en :  JSON.parse(res.data.name).en , ar : JSON.parse(res.data.name).ar})
            setCountryId(res.data.country.id)
        }).catch(err => history.push('/governments/1'))
        fetchAllCountries().then(res => {
            setCountries(res.data)
        }).catch(err => history.push('/governments/1'))
    } , [history , props.id])

    const handleUpdateGovernment = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        country.name !== name ? formData.append('name' , JSON.stringify(name)) : <></>
        formData.append('country_id' , countryId)
        await updateGovernment(props.id , formData).then(res => history.goBack()).catch(e => setError(e.response.data.errors[0].message))
    }

    return (
        <div className="row" style={lang === "ar" ? {direction:'rtl' , textAlign : 'right'} : {direction:'ltr' , textAlign : 'left'}}>
        <div className="col-md-12">
            <div className="main-card mb-3 card">
                <div className="card-body">
                    <h5 className="card-title">{lang === "en" ? "Edit Government" : "تعديل محافظة"}</h5>
                    <form id="signupForm" onSubmit={handleUpdateGovernment} className="col-md-10 mx-auto" method="post">

                    <div className="form-group">
                          <label for="device">{lang === "en" ? "Choose Country" : "اختر البلد"} *</label>
                          <select className="form-control" onChange={(e) => setCountryId(e.target.value)}>
                          <option value="0" key="0">{lang === "en" ? "Choose Country" : "اختر البلد"}</option>
                              {
                                  countries.map((value , key) => (
                                    <option value={value.id} selected={value.id === countryId ? true : false} key={key}>{lang === "en" ? JSON.parse(value.name).en : JSON.parse(value.name).ar}</option>
                                  ))
                              }
                          </select>
                      </div>

                        <div className="form-group">
                            <label for="name">{lang === "en" ? "English Name" : "الأسم بالإنجليزية"} *</label>
                            <div>
                                <input type="text" className="form-control" value={name.en} onChange={e => setName({...name , en : e.target.value})} placeholder={lang === "en" ? "English Name" : "الأسم بالإنجليزية"} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label for="name">{lang === "en" ? "Arabic Name" : "الأسم بالعربية"} *</label>
                            <div>
                                <input type="text" className="form-control" value={name.ar} onChange={e => setName({...name , ar : e.target.value})} placeholder={lang === "en" ? "Arabic Name" : "الأسم بالعربية"} />
                            </div>
                        </div>
  
                        {error.length !== 0 ? <div className="alert alert-danger">{error.length === 24 || error.length === 20 ? error : lang === "en" ? JSON.parse(error).en : JSON.parse(error).ar}</div> : ''}
  
                        <div className="form-group">
                            <button type="submit" className="btn btn-primary">{lang === "en" ? "Edit Government" : "تعديل محافظة"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>)
}

export default EditGovernment
