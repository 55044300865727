import React , { useEffect , useState } from 'react'
import { countUsers , countDevices , countCountries , countGovernments , countIndustrialCities , countIndustrialZones } from '../api/API'
function Dashboard() {
    const [usersCount , setUsersCount] = useState(0)
    const [devicesCount , setDevicesCount] = useState(0)
    const [countriesCount , setCountriesCount] = useState(0)
    const [governmentsCount , setGovernmentsCount] = useState(0)
    const [industrialCitiesCount , setindustrialCitiesCount] = useState(0)
    const [industrialZonesCount , setindustrialZonesCount] = useState(0)

    const [lang] = useState(localStorage.getItem("lang") || "en")

    const [error , setError] = useState('')

    const getUsersCount = async () => {
        await countUsers().then(res => setUsersCount(res.data[0].total)).catch(e => setError(e.response.data.message))
    }

    const getDevicesCount = async () => {
        await countDevices().then(res => setDevicesCount(res.data[0].total)).catch(e => setError(e.response.data.message))
    }

    const getCountriesCount = async () => {
        await countCountries().then(res => setCountriesCount(res.data[0].total)).catch(e => setError(e.response.data.message))
    }

    const getGovernmentsCount = async () => {
        await countGovernments().then(res => setGovernmentsCount(res.data[0].total)).catch(e => setError(e.response.data.message))
    }

    const getindustrialCitiesCount = async () => {
        await countIndustrialCities().then(res => setindustrialCitiesCount(res.data[0].total)).catch(e => setError(e.response.data.message))
    }

    const getindustrialZonesCount = async () => {
        await countIndustrialZones().then(res => setindustrialZonesCount(res.data[0].total)).catch(e => setError(e.response.data.message))
    }

    useEffect(() => {
        getUsersCount()
        getDevicesCount()
        getCountriesCount()
        getGovernmentsCount()
        getindustrialCitiesCount()
        getindustrialZonesCount()
    }, [])
    return (
        !error
        ?
        <div className="mb-3 card">
        <div className="card-header-tab card-header">
            <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                <i className="header-icon lnr-charts icon-gradient bg-happy-green"> </i>
                {lang === "en" ? "Dashboard" : "لوحة التحكم"}
            </div>
        </div>
        <div className="no-gutters row">
            <div className="col-sm-6 col-md-4 col-xl-4">
                <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div className="icon-wrapper rounded-circle">
                        <div className="icon-wrapper-bg opacity-10 bg-warning"></div>
                        <i className="fas fa-users text-dark opacity-8"></i></div>
                    <div className="widget-chart-content">
                        <div className="widget-subheading">{lang === "en" ? "Users" : "المستخدمين"}</div>
                        <div className="widget-numbers">{usersCount}</div>
                    </div>
                </div>
                <div className="divider m-0 d-md-none d-sm-block"></div>
            </div>
            <div className="col-sm-6 col-md-4 col-xl-4">
                <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div className="icon-wrapper rounded-circle">
                        <div className="icon-wrapper-bg opacity-10 bg-warning"></div>
                        <i className="fas fa-tachometer-alt text-dark opacity-8"></i></div>
                    <div className="widget-chart-content">
                        <div className="widget-subheading">{lang === "en" ? "Devices" : "الأجهزة"}</div>
                        <div className="widget-numbers">{devicesCount}</div>
                    </div>
                </div>
                <div className="divider m-0 d-md-none d-sm-block"></div>
            </div>

            <div className="col-sm-6 col-md-4 col-xl-4">
                <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div className="icon-wrapper rounded-circle">
                        <div className="icon-wrapper-bg opacity-10 bg-warning"></div>
                        <i className="fas fa-globe-africa text-dark opacity-8"></i></div>
                    <div className="widget-chart-content">
                        <div className="widget-subheading">{lang === "en" ? "Countries" : "البلاد"}</div>
                        <div className="widget-numbers">{countriesCount}</div>
                    </div>
                </div>
                <div className="divider m-0 d-md-none d-sm-block"></div>
            </div>

            <div className="col-sm-6 col-md-4 col-xl-4">
                <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div className="icon-wrapper rounded-circle">
                        <div className="icon-wrapper-bg opacity-10 bg-warning"></div>
                        <i className="fas fa-globe-africa text-dark opacity-8"></i></div>
                    <div className="widget-chart-content">
                        <div className="widget-subheading">{lang === "en" ? "Governments" : "المحافظات"}</div>
                        <div className="widget-numbers">{governmentsCount}</div>
                    </div>
                </div>
                <div className="divider m-0 d-md-none d-sm-block"></div>
            </div>

            <div className="col-sm-6 col-md-4 col-xl-4">
                <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div className="icon-wrapper rounded-circle">
                        <div className="icon-wrapper-bg opacity-10 bg-warning"></div>
                        <i className="fas fa-globe-africa text-dark opacity-8"></i></div>
                    <div className="widget-chart-content">
                        <div className="widget-subheading">{lang === "en" ? "Industrial Cities" : "المدن الصناعية"}</div>
                        <div className="widget-numbers">{industrialCitiesCount}</div>
                    </div>
                </div>
                <div className="divider m-0 d-md-none d-sm-block"></div>
            </div>

            <div className="col-sm-6 col-md-4 col-xl-4">
                <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div className="icon-wrapper rounded-circle">
                        <div className="icon-wrapper-bg opacity-10 bg-warning"></div>
                        <i className="fas fa-globe-africa text-dark opacity-8"></i></div>
                    <div className="widget-chart-content">
                        <div className="widget-subheading">{lang === "en" ? "Industrial Zones" : "المناطق الصناعية"}</div>
                        <div className="widget-numbers">{industrialZonesCount}</div>
                    </div>
                </div>
                <div className="divider m-0 d-md-none d-sm-block"></div>
            </div>


        </div>
    </div> 
    :
    <div className="alert alert-danger">{error}</div>
    )
}

export default Dashboard
