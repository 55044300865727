import React, { useState,  useEffect } from 'react'
import { Link, useHistory } from "react-router-dom"
import { getAccount } from '../api/API'

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import UsersIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import DashboardIcon from '@material-ui/icons/Dashboard';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import BuildIcon from '@material-ui/icons/Build';
import PublicIcon from '@material-ui/icons/Public';
import LanguageIcon from '@material-ui/icons/Language';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));


function Sidebar() {
  let history = useHistory()
    const classes = useStyles();
    const [userData , setUserData] = useState({})
    const [lang] = useState(localStorage.getItem("lang") || "en")

    useEffect(() => {
        getUserData()
    } , [])

    const changeLang = (language , value) => {
      localStorage.setItem(language , value)
      window.location.reload()
    }

    const getUserData = async () => {
        await getAccount().then(res => setUserData(res.data)).catch(err => {})
    }

    const [openUsers, setOpenUsers] = useState(false);
    const [openDevices, setOpenDevices] = useState(false);
    const [openAccount, setOpenAccount] = useState(false);
    const [openCountries, setOpenCountries] = useState(false);
    const [openGovernments, setOpenGovernments] = useState(false);
    const [openCities, setOpenCities] = useState(false);
    const [openZones, setOpenZones] = useState(false);



  const handleClickUsers = () => {
    setOpenUsers(!openUsers);
  };

  const handleClickDevices = () => {
    setOpenDevices(!openDevices);
  };

  const handleClickCountries = () => {
    setOpenCountries(!openCountries);
  };

  const handleClickAccount = () => {
    setOpenAccount(!openAccount);
  };

  const handleClickGovernments = () => {
    setOpenGovernments(!openGovernments);
  };

  const handleClickIndustrialCities = () => {
    setOpenCities(!openCities);
  };

  const handleClickIndustrialZones = () => {
    setOpenZones(!openZones);
  };

    return (
        userData ?
        <div className="app-sidebar-wrapper">
        <div className="app-sidebar sidebar-shadow">
            <div className="app-header__logo">
                <Link to="/" style={{ color : '#dc3545' , fontSize : 22 }}>Qodeex</Link>
            </div>
            <div className="scrollbar-sidebar scrollbar-container" style={{ overflowX : 'auto' }}>
                <div className="app-sidebar__inner">
                <div className={classes.root}>
      <List component="nav" aria-label="main mailbox folders">
        <Link to="/">
        <ListItem button>
          <ListItemIcon>
            <DashboardIcon htmlColor="red" />
          </ListItemIcon>
          <ListItemText primary={lang === "en" ? "Dashboard" : "لوحة التحكم"} />
        </ListItem>
        </Link>
        <Divider />



        <ListItem button onClick={handleClickAccount}>
        <ListItemIcon>
          <AccountBoxIcon htmlColor="black" />
        </ListItemIcon>
        <ListItemText primary={lang === "en" ? "My Account" : "حسابى"} />
        {openAccount ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
        <Collapse in={openAccount} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <Link to="#" onClick={() => lang === "en" ? changeLang("lang" , "ar") : changeLang("lang" , "en")}>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <LanguageIcon htmlColor="red" />
            </ListItemIcon>
            <ListItemText primary={lang === "en" ? "عربى" : "English"} />
          </ListItem>
          </Link>
        <Link to="/editProfile">
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <AccountCircleIcon htmlColor="lightgreen" />
            </ListItemIcon>
            <ListItemText primary={lang === "en" ? "My Profile" : "ملفى الشخصى"} />
          </ListItem>
          </Link>
          <Link to="/changePassword">
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <LockOpenIcon htmlColor="lightgreen" />
            </ListItemIcon>
            <ListItemText primary={lang === "en" ? "Change Password" : "تغيير كلمة المرور"} />
          </ListItem>
          </Link>
          <Link to="#" onClick={() => {
            localStorage.removeItem("token")
            history.push("/login")
          }}>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <ExitToAppIcon htmlColor="red" />
            </ListItemIcon>
            <ListItemText primary={lang === "en" ? "Logout" : "تسجيل الخروج"} />
          </ListItem>
          </Link>
        </List>
      </Collapse>
      <Divider />



      <ListItem button onClick={handleClickUsers}>
        <ListItemIcon>
          <AccountBoxIcon htmlColor="black" />
        </ListItemIcon>
        <ListItemText primary={lang === "en" ? "Users" : "المستخدمين"} />
        {openUsers ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
        <Collapse in={openUsers} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="/users/1">
          <ListItem button className={classes.nested}>
            <ListItemIcon>
            <UsersIcon htmlColor="purple" />
            </ListItemIcon>
            <ListItemText primary={lang === "en" ? "Show Users" : "إظهار المستخدمين"} />
          </ListItem>
          </Link>
          <Link to="/user/add">
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <PersonIcon htmlColor="orange" />
            </ListItemIcon>
            <ListItemText primary={lang === "en" ? "Add User" : "إضافة مستخدم"} />
          </ListItem>
          </Link>
        </List>
      </Collapse>
      <Divider />


      <ListItem button onClick={handleClickDevices}>
        <ListItemIcon>
          <HomeWorkIcon htmlColor="black" />
        </ListItemIcon>
        <ListItemText primary={lang === "en" ? "Devices" : "الأجهزة"} />
        {openDevices ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
        <Collapse in={openDevices} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="/devices/1">
          <ListItem button className={classes.nested}>
            <ListItemIcon>
            <BuildIcon htmlColor="purple" />
            </ListItemIcon>
            <ListItemText primary={lang === "en" ? "Show Devices" : "إظهار الأجهزة"} />
          </ListItem>
          </Link>
          <Link to="/device/add">
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <BuildIcon htmlColor="orange" />
            </ListItemIcon>
            <ListItemText primary={lang === "en" ? "Add Device" : "إضافة جهاز"} />
          </ListItem>
          </Link>
        </List>
      </Collapse>
      <Divider />


      <ListItem button onClick={handleClickCountries}>
        <ListItemIcon>
          <PublicIcon htmlColor="black" />
        </ListItemIcon>
        <ListItemText primary={lang === "en" ? "Countries" : "البلاد"} />
        {openCountries ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
        <Collapse in={openCountries} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="/countries/1">
          <ListItem button className={classes.nested}>
            <ListItemIcon>
            <PublicIcon htmlColor="green" />
            </ListItemIcon>
            <ListItemText primary={lang === "en" ? "Show Countries" : "إظهار البلاد"} />
          </ListItem>
          </Link>
          <Link to="/country/add">
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <PublicIcon htmlColor="blue" />
            </ListItemIcon>
            <ListItemText primary={lang === "en" ? "Add Country" : "إضافة بلد"} />
          </ListItem>
          </Link>
        </List>
      </Collapse>
      <Divider />





      <ListItem button onClick={handleClickGovernments}>
        <ListItemIcon>
          <PublicIcon htmlColor="black" />
        </ListItemIcon>
        <ListItemText primary={lang === "en" ? "Governments" : "المحافظات"} />
        {openGovernments ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
        <Collapse in={openGovernments} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="/governments/1">
          <ListItem button className={classes.nested}>
            <ListItemIcon>
            <PublicIcon htmlColor="green" />
            </ListItemIcon>
            <ListItemText primary={lang === "en" ? "Show Governments" : "إظهار المحافظات"} />
          </ListItem>
          </Link>
          <Link to="/government/add">
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <PublicIcon htmlColor="blue" />
            </ListItemIcon>
            <ListItemText primary={lang === "en" ? "Add Government" : "إضافة محافظة"} />
          </ListItem>
          </Link>
        </List>
      </Collapse>
      <Divider />



      <ListItem button onClick={handleClickIndustrialCities}>
        <ListItemIcon>
          <PublicIcon htmlColor="black" />
        </ListItemIcon>
        <ListItemText primary={lang === "en" ? "Industrial Cities" : "المدن الصناعية"} />
        {openCities ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
        <Collapse in={openCities} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="/industrialCities/1">
          <ListItem button className={classes.nested}>
            <ListItemIcon>
            <PublicIcon htmlColor="green" />
            </ListItemIcon>
            <ListItemText primary={lang === "en" ? "Show Industrial Cities" : "إظهار المدن الصناعية"} />
          </ListItem>
          </Link>
          <Link to="/industrialCity/add">
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <PublicIcon htmlColor="blue" />
            </ListItemIcon>
            <ListItemText primary={lang === "en" ? "Add Industrial City" : "إضافة مدينة صناعية"} />
          </ListItem>
          </Link>
        </List>
      </Collapse>
      <Divider />



      <ListItem button onClick={handleClickIndustrialZones}>
        <ListItemIcon>
          <PublicIcon htmlColor="black" />
        </ListItemIcon>
        <ListItemText primary={lang === "en" ? "Industrial Zones" : "المناطق الصناعية"} />
        {openZones ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
        <Collapse in={openZones} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="/industrialZones/1">
          <ListItem button className={classes.nested}>
            <ListItemIcon>
            <PublicIcon htmlColor="green" />
            </ListItemIcon>
            <ListItemText primary={lang === "en" ? "Show Industrial Zones" : "إظهار المناطق الصناعية"} />
          </ListItem>
          </Link>
          <Link to="/industrialZone/add">
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <PublicIcon htmlColor="blue" />
            </ListItemIcon>
            <ListItemText primary={lang === "en" ? "Add Industrial Zone" : "إضافة منطقة صناعية"} />
          </ListItem>
          </Link>
        </List>
      </Collapse>
      <Divider />


      </List>
    </div>
    </div>
            </div>
        </div>
    </div>
        : ''
       
    )
}

export default Sidebar
