import React , { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { addIndustrialZone , fetchIndustrialCities } from '../api/API'

function AddIndustrialZone() {
    let history = useHistory()
    const [error ,setError] = useState([])
    const [industrialCityId , setIndustrialCityId] = useState(0)
    const [industrialCities , setIndustrialCities] = useState([])
    const [name , setName] = useState({en : '' , ar : ''})
    const [lang] = useState(localStorage.getItem("lang") || "en")

    useEffect(() => {
        const fetchIndustrialCitiesAPI = async () => {
            await fetchIndustrialCities().then(res => setIndustrialCities(res.data.data))
        }
        fetchIndustrialCitiesAPI()
    } , [])

    const handleCreateIndustrialCity = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        if(!name.en || !name.ar)
        setError('Please Fill All Data')
        else{
    
        if(industrialCityId !== 0 )
        formData.append('industrial_city_id' , industrialCityId)
        if(name !== '' )
        formData.append('name' , JSON.stringify(name))
        await addIndustrialZone(formData).then(res => history.goBack()).catch(e => setError(e.response.data.errors[0].message))
        }
    }

    return (
      <div className="row" style={lang === "ar" ? {direction:'rtl' , textAlign : 'right'} : {direction:'ltr' , textAlign : 'left'}}>
      <div className="col-md-12">
          <div className="main-card mb-3 card">
              <div className="card-body">
                  <h5 className="card-title">{lang === "en" ? "Add Industrial Zone" : "إضافة منطقة صناعية"}</h5>
                  <form id="signupForm" onSubmit={handleCreateIndustrialCity} className="col-md-10 mx-auto" method="post">
                      <div className="form-group">
                          <label for="industrial_city">{lang === "en" ? "Choose Industrial City" : "اختر المدينة الصناعية"} *</label>
                          <select className="form-control" onChange={(e) => setIndustrialCityId(e.target.value)}>
                          <option value="0" key="0">{lang === "en" ? "Choose Industrial City" : "اختر المدينة الصناعية"}</option>
                              {
                                  industrialCities.map((value , key) => (
                                    <option value={value.id} key={key}>{lang === "en" ? JSON.parse(value.name).en : JSON.parse(value.name).ar}</option>
                                  ))
                              }
                          </select>
                      </div>
                      <div className="form-group">
                          <label for="name">{lang === "en" ? "English Name" : "الأسم بالإنجليزية"} *</label>
                          <div>
                              <input type="text" className="form-control" value={name.en} onChange={e => setName({...name , en : e.target.value})} placeholder={lang === "en" ? "English Name" : "الأسم بالإنجليزية"} />
                          </div>
                      </div>
                      <div className="form-group">
                          <label for="name">{lang === "en" ? "Arabic Name" : "الأسم بالعربية"} *</label>
                          <div>
                              <input type="text" className="form-control" value={name.ar} onChange={e => setName({...name , ar : e.target.value})} placeholder={lang === "en" ? "Arabic Name" : "الأسم بالعربية"} />
                          </div>
                      </div>

                      {error.length !== 0 ? <div className="alert alert-danger">{error.length === 24 || error.length === 20 ? error : lang === "en" ? JSON.parse(error).en : JSON.parse(error).ar}</div> : ''}

                      <div className="form-group">
                          <button type="submit" className="btn btn-primary">{lang === "en" ? "Add Industrial Zone" : "إضافة منطقة صناعية"}</button>
                      </div>
                  </form>
              </div>
          </div>
      </div>
  </div>
  )
}

export default AddIndustrialZone
