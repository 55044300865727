import React , { useState } from 'react'
import { register } from '../api/API'
import { Link } from 'react-router-dom'

function Register() {
    const [username , setUsername] = useState('')
    const [email , setEmail] = useState('')
    const [password , setPassword] = useState('')
    const [error ,setError] = useState([])


    const handleRegister = async (e) => {
        e.preventDefault()
        const data = new FormData()
        data.append('username' , username)
        data.append('email' , email)
        data.append('password' , password)

        await register(data)
            .then( res => { 
                localStorage.setItem("token" , res.data.token.token)
                window.location = "/"
            })
            .catch( err => {
                setError(err.response.data.errors[0].message) 
            })
    }
    
    return (
        <div className="app-container app-theme-white body-tabs-shadow">
        <div className="app-container">
            <div className="h-100 bg-plum-plate bg-animation">
                <div className="d-flex h-100 justify-content-center align-items-center">
                    <div className="mx-auto app-login-box col-md-8">
                        <div className="mx-auto mb-3"></div>
                        <div className="modal-dialog w-100 mx-auto">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="h5 modal-title text-center">
                                        <h4 className="mt-2">
                                            <div>Welcome back,</div>
                                            <span>Please sign up your account.</span>
                                        </h4>
                                    </div>
                                    <form onSubmit={handleRegister}>
                                        <div className="form-row">
                                        <div className="col-md-12">
                                                <div className="position-relative form-group"><input value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Enter username" type="text" className="form-control" /></div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="position-relative form-group"><input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter email address" type="email" className="form-control" /></div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="position-relative form-group"><input value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Enter password" type="password" className="form-control" /></div>
                                            </div>
                                        </div>
                                        <div className="divider"></div>
                                    <h6 className="mb-0">Already Have an account? <Link to="/login" className="text-primary">Sign in</Link></h6>
                           <br></br>
                           {error.length !== 0 ? <div className="alert alert-danger">{error.length === 24 ? error : JSON.parse(error).en}</div> : ''}
                               <br></br>
                                        <div className="modal-footer clearfix">
                                    <div className="float-right">
                                        <input type="submit" className="btn btn-primary btn-lg" value="Register to Dashboard" />
                                    </div>
                                </div>
                                    </form>
                                     </div>
                                
                            </div>
                        </div>
                        <div className="text-center text-white opacity-8 mt-3">Copyright © Qodeex 2021</div>
                    </div>
                </div>
            </div>
        </div>
</div>
    )
}

export default Register
