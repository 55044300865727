import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import LoginComponent from '../components/Login'

function Login() {
    const history = useHistory()
    const [ loding, setLoding ] = useState(false)
    useEffect(() => {
        if(localStorage.getItem("token")){
            history.push("/")
        }
        setLoding(true)
    }, [history])
   

    return (
        loding ? 
            <LoginComponent /> : ""
    )
}

export default Login
