import React , { useState , useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { showUser , deleteUserDevice } from '../api/API'
function ModalUser(props) {
    let history = useHistory()
    const [loading , setLoading] = useState(false)
    const [user , setUser] = useState({})
    const [userDevices , setUserDevices] = useState([])
    const [lang] = useState(localStorage.getItem("lang") || "en")

    useEffect(() => {
        showUser(props.id).then(res => { setUser(res.data); setUserDevices(res.data.userDevices) }).catch(err => {})
        setLoading(true)
    } , [props.id , props.rand])
    const handleDeleteUserDevice = async (userId , id) => {
        await deleteUserDevice(userId , id).then((res) => {setUserDevices(userDevices.filter((userDevice) => userDevice.id !== id))}).catch((e) => {})
    }
    return (
        loading ?
        <div className="modal fade show" style={{display:'block' , overflowY:'auto'}} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{user.username}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setLoading(false)}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body" style={lang === "ar" ? {textAlign : 'right' , direction : 'rtl'} : {textAlign : 'left' , direction : 'ltr'}}>
            <div className={`alert alert-${user.email ? 'success' : 'danger'}`}><span>{lang === "en" ? "Email" : "البريد الإلكترونى"} : </span><span>{user.email}</span></div>
            <div className={`alert alert-${user.address ? 'success' : 'danger'}`}><span>{lang === "en" ? "Address" : "العنوان"} : </span><span>{user.address}</span></div>
            <div className={`alert alert-${user.phone ? 'success' : 'danger'}`}><span>{lang === "en" ? "Phone" : "رقم الهاتف"} : </span><span>{user.phone}</span></div>
            <div className={`alert alert-${user.countryUser ? 'success' : 'danger'}`}><span>{lang === "en" ? "Country" : "البلد"} : </span><span>{ user.countryUser ? lang === "en" ? JSON.parse(user.countryUser.name).en : JSON.parse(user.countryUser.name).ar : ''}</span></div>
            <div className={`alert alert-${user.state ? 'success' : 'danger'}`}><span>{lang === "en" ? "State" : "محل الإقامة"} : </span><span>{user.state}</span></div>
            <div className={`alert alert-${user.website ? 'success' : 'danger'}`}><span>{lang === "en" ? "Website" : "الموقع الإلكترونى"} : </span><span>{user.website}</span></div>
            <div style={{ color:'white' , fontWeight : 'bold' , marginTop: 5 , marginBottom: 15 , display:'flex' , justifyContent:'center' , alignItems : 'center' , height:50,backgroundColor: 'green' }}>{lang === "en" ? "User Devices" : "أجهزة المستخدم"}</div>
            {
                userDevices.length > 0 ?
                userDevices.map((value , key) => {
                   return <div key={key}>
                           <div style={{backgroundColor : 'black' , color: 'white' , fontWeight: 'bolder'}} className={`alert alert-${JSON.parse(value.deviceType.name).en ? 'success' : 'danger'}`}><span>{lang === "en" ? "English Device Name" : "أسم الجهاز بالإنجليزية"} : </span><span>{JSON.parse(value.deviceType.name).en}</span></div>
                           <div style={{backgroundColor : 'black' , color: 'white' , fontWeight: 'bolder'}} className={`alert alert-${JSON.parse(value.deviceType.name).ar ? 'success' : 'danger'}`}><span>{lang === "en" ? "English Device Name" : "أسم الجهاز بالعربية"} : </span><span>{JSON.parse(value.deviceType.name).ar}</span></div>
                           <div style={{backgroundColor : 'black' , color: 'white' , fontWeight: 'bolder'}} className={`alert alert-${value.device_number ? 'success' : 'danger'}`}><span>{lang === "en" ? "Device Number" : "رقم الجهاز"} : </span><span>{value.device_number}</span></div>
                        <div style={{ position: 'relative' , width : '100%' , height : 60 }}>
                        <div onClick={() => history.push(`/userDevice/${user.id}`)} style={{ position : 'absolute' , left : 0 , width : 150 , height : 40 , cursor : 'pointer' , backgroundColor : 'blue' , fontWeight : 'bold' , color: 'white' , display : 'flex' , justifyContent:'center' , alignItems : 'center' }}>{lang === "en" ? "Add new Device" : "إضافة جهاز جديد"}</div>
                        {value.data_reads.length > 0 ? <div onClick={() => history.push(`/dataReads/${value.id}`)} style={{ position : 'absolute' , left:'40%', width : 150 , height : 40 , cursor : 'pointer' , backgroundColor : 'green' , fontWeight : 'bold' , color: 'white' , display : 'flex' , justifyContent:'center' , alignItems : 'center' }}>{lang === "en" ? "History" : "السجل"}</div> : ''}
                        <div onClick={() => handleDeleteUserDevice(value.user_id , value.id)} style={{ position : 'absolute' , right : 0 , width : 100 , height : 40 , cursor : 'pointer' , backgroundColor : 'red' , fontWeight : 'bold' , color: 'white' , display : 'flex' , justifyContent:'center' , alignItems : 'center' }}>{lang === "en" ? "Delete" : "حذف"}</div>
                        </div>
                        </div>
                }) : <>
                <div className="alert alert-danger">{lang === "en" ? "No devices are related to user." : "لا يوجد أجهزة مربوطة بالمستخدم."}</div>
                <div style={{ position: 'relative' , width : '100%' , height : 60 }}>
                        <div onClick={() => history.push(`/userDevice/${user.id}`)} style={{ position : 'absolute' , left : 0 , width : 150 , height : 40 , cursor : 'pointer' , backgroundColor : 'blue' , fontWeight : 'bold' , color: 'white' , display : 'flex' , justifyContent:'center' , alignItems : 'center' }}>{lang === "en" ? "Add new Device" : "إضافة جهاز جديد"}</div>
                        </div>
                        </>
            }
           </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setLoading(false)} data-dismiss="modal">{lang === "en" ? "Close" : "إغلاق"}</button>
            </div>
        </div>
    </div>
</div>
        : ''
    )
}

export default ModalUser
