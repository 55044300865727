import React , { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { updateCountry , showCountry } from '../api/API'
// import EditUser from '../pages/EditUser'

function EditCountry(props) {
    const [country , setCountry] = useState({})
    let history = useHistory()
    const [name , setName] = useState({ en : '' , ar : '' })
    const [iso_3 , setIso_3] = useState('')
    const [phone_code , setPhone_Code] = useState('')
    const [status , setStatus] = useState(false)
    const [error ,setError] = useState('')
    const [lang] = useState(localStorage.getItem("lang") || "en")

    useEffect(() => {
        showCountry(props.id).then(res => {
            setCountry(res.data)
            setName({ en :  JSON.parse(res.data.name).en , ar : JSON.parse(res.data.name).ar})
            setIso_3(res.data.iso_3)
            setPhone_Code(res.data.phone_code)
            setStatus(res.data.status)
        }).catch(err => err.response.data.check === false ? history.push('/countries/1') : '')
    } , [history , props.id])

    const handleupdateCountry = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        country.name !== name ? formData.append('name' , JSON.stringify(name)) : <></>
        formData.append('iso_3' , iso_3)
        formData.append('phone_code' , phone_code)
        status ? formData.append('status' , 1) : formData.append('status' , 0)
        await updateCountry(props.id , formData).then(res => history.goBack()).catch(e => setError(e.response.data.errors[0].message))
    }

    return (
        <div className="row" style={lang === "ar" ? {direction:'rtl' , textAlign : 'right'} : {direction:'ltr' , textAlign : 'left'}}>
        <div className="col-md-12">
            <div className="main-card mb-3 card">
                <div className="card-body">
                    <h5 className="card-title">{lang === "en" ? "Edit Country" : "تعديل بلد"}</h5>
                    <form id="signupForm" onSubmit={handleupdateCountry} className="col-md-10 mx-auto" method="post">
                        <div className="form-group">
                            <label for="name">{lang === "en" ? "English Name" : "الأسم بالإنجليزية"} *</label>
                            <div>
                                <input type="text" className="form-control" value={name.en} onChange={e => setName({...name , en : e.target.value})} placeholder={lang === "en" ? "English Name" : "الأسم بالإنجليزية"} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label for="name">{lang === "en" ? "Arabic Name" : "الأسم بالعربية"} *</label>
                            <div>
                                <input type="text" className="form-control" value={name.ar} onChange={e => setName({...name , ar : e.target.value})} placeholder={lang === "en" ? "Arabic Name" : "الأسم بالعربية"} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label for="iso_3">{lang === "en" ? "ISO 3" : "ترميز البلد"} *</label>
                            <div>
                                <textarea className="form-control" value={iso_3} onChange={e => setIso_3(e.target.value)} placeholder={lang === "en" ? "ISO 3" : "ترميز البلد"}></textarea>
                            </div>
                        </div>
                        <div className="form-group">
                            <label for="phoneCode">{lang === "en" ? "Phone Code" : "كود الهاتف"} *</label>
                            <div>
                                <textarea className="form-control" value={phone_code} onChange={e => setPhone_Code(e.target.value)} placeholder={lang === "en" ? "Phone Code" : "كود الهاتف"}></textarea>
                            </div>
                        </div>

                        <div className="form-group">
                          <label for="status">{lang === "en" ? "Status" : "الحالة"} *</label>
                          <div>
                              <input type="checkbox" className="form-control" checked={status} onChange={e => setStatus(!status)} placeholder={lang === "en" ? "Status" : "الحالة"} />
                          </div>
                      </div>
  
                        {error.length !== 0 ? <div className="alert alert-danger">{error.length === 24 || error.length === 20 ? error : lang === "en" ? JSON.parse(error).en : JSON.parse(error).ar}</div> : ''}
  
                        <div className="form-group">
                            <button type="submit" className="btn btn-primary">{lang === "en" ? "Edit Country" : "تعديل بلد"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>)
}

export default EditCountry
