import React , { useState } from 'react'
import { createAccount } from '../api/API'

function AddUser() {
    const [error ,setError] = useState([])
    const [username , setUsername] = useState('')
    const [email , setEmail] = useState('')
    const [password , setPassword] = useState('')
    const [lang] = useState(localStorage.getItem("lang") || "en")

    const handleCreateAccount = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('username' , username)
        formData.append('email' , email)
        formData.append('password' , password)
        await createAccount(formData).then(res => window.location = "/users/1").catch(e => setError(e.response.data.errors[0].message))
    }

    return (
      <div className="row" style={lang === "ar" ? {direction:'rtl' , textAlign : 'right'} : {direction:'ltr' , textAlign : 'left'}}>
      <div className="col-md-12">
          <div className="main-card mb-3 card">
              <div className="card-body">
                  <h5 className="card-title">{lang === "en" ? "Add User" : "إضافة مستخدم"}</h5>
                  <form id="signupForm" onSubmit={handleCreateAccount} className="col-md-10 mx-auto" method="post">
                      <div className="form-group">
                          <label for="username">{lang === "en" ? "Username" : "أسم المستخدم"} *</label>
                          <div>
                              <input type="text" className="form-control" value={username} onChange={e => setUsername(e.target.value)} placeholder={lang === "en" ? "Username" : "أسم المستخدم"} />
                          </div>
                      </div>
                      <div className="form-group">
                          <label for="email">{lang === "en" ? "Email" : "البريد الإلكترونى"} *</label>
                          <div>
                              <input type="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)} placeholder={lang === "en" ? "Email" : "البريد الإلكترونى"} />
                          </div>
                      </div>
                      <div className="form-group">
                          <label for="password">{lang === "en" ? "Password" : "كلمة المرور"} *</label>
                          <div>
                              <input type="password" className="form-control" value={password} onChange={e => setPassword(e.target.value)} placeholder={lang === "en" ? "Password" : "كلمة المرور"} />
                          </div>
                      </div>
                     
                      {error.length !== 0 ? <div className="alert alert-danger">{error.length === 24 ? error : lang === "en" ? JSON.parse(error).en : JSON.parse(error).ar}</div> : ''}

                      <div className="form-group">
                          <button type="submit" className="btn btn-primary">{lang === "en" ? "Add User" : "إضافة مستخدم"}
                          </button>
                      </div>
                  </form>
              </div>
          </div>
      </div>
  </div>
  )
}

export default AddUser
