import React , { useState , useEffect } from 'react'
import { showCountry } from '../api/API'

function ModalCountry(props) {
    const [loading , setLoading] = useState(false)
    const [country , setCountry] = useState({})
    const [lang] = useState(localStorage.getItem("lang") || "en")

    useEffect(() => {
        showCountry(props.id).then(res => setCountry(res.data)).catch(err => {})
        setLoading(true)
    } , [props.id , props.rand])
    
    return (
        loading && country.name ?
        <div className="modal fade show" style={{display:'block'}} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{lang === "en" ? JSON.parse(country.name).en : JSON.parse(country.name).ar}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setLoading(false)}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body" style={lang === "ar" ? {textAlign : 'right' , direction : 'rtl'} : {textAlign : 'left' , direction : 'ltr'}}>
            <div className={`alert alert-${JSON.parse(country.name).en ? 'success' : 'danger'}`}><span>{lang === "en" ? "English Name" : "الأسم بالإنجليزية"} : </span><span>{JSON.parse(country.name).en}</span></div>
            <div className={`alert alert-${JSON.parse(country.name).ar ? 'success' : 'danger'}`}><span>{lang === "en" ? "Arabic Name" : "الأسم بالعربية"} : </span><span>{JSON.parse(country.name).ar}</span></div>
            <div className={`alert alert-${country.iso_3 ? 'success' : 'danger'}`}><span>{lang === "en" ? "ISO 3" : "ترميز البلد"} : </span><span>{country.iso_3}</span></div>
            <div className={`alert alert-${country.phone_code ? 'success' : 'danger'}`}><span>{lang === "en" ? "Phone Code" : "كود الهاتف"} : </span><span>{country.phone_code}</span></div>
            <div className={`alert alert-${country.status === 1 ? 'success' : 'danger'}`}><span>{lang === "en" ? "Status" : "الحالة"} : </span><span>{country.status === 1 ? 'Active' : 'Not Active'}</span></div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setLoading(false)} data-dismiss="modal">{lang === "en" ? "Close" : "إغلاق"}</button>
            </div>
        </div>
    </div>
</div>
        : ''
    )
}

export default ModalCountry
