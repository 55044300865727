import React , {useEffect} from 'react'
import { BrowserRouter, Switch,Route, Redirect} from 'react-router-dom'
// import "./App.css"
import routes from './routes'
import PrivateRouter from './PrivateRouter'

import Login from './pages/Login'
import Register from './pages/Register'

// import NotFound from './components/404'

function App() {

  useEffect(() => {
    const langChosen = () => {
      if(!localStorage.getItem("lang")){
      localStorage.setItem("lang" , "en")
    }
    return true
    }
    langChosen()
  } , [])
  return (
    <BrowserRouter>
      <Switch>
        {
            routes.map((route, i) => 
              <PrivateRouter key={i} authed={ route.auth } exact={ route.exact } path={ route.path } component={route.component} />
            ) 
        }
        <Route path='/login'>
          <Login />
      </Route>
      <Route path='/register'>
          <Register />
      </Route>
        <Redirect path="*" to="/login" />
      </Switch>
    </BrowserRouter>
  )
}

export default App
