import axios from 'axios'
export const baseURL = "https://october.qodeex.com/api"

// export const baseURL = "http://127.0.0.1:3333/api"

export const instance = axios.create({
    baseURL: baseURL,
    headers: { 
        "Authorization" : `Bearer ${localStorage.getItem("token")}`
    }
  });
  
    export const register = async (data) => {
        return await instance.post(`${baseURL}/register` , data)
    }

    export const logout = async () => {
        return await instance.delete(`${baseURL}/logout`)
    }

    export const login = async (data) => {
        return await instance.post(`${baseURL}/login` , data)
    }
    
    export const fetchUsers = async (page) => {
        return await instance.get(`${baseURL}/users/${page}`)
    }

    export const fetchDevices = async (page) => {
        return await instance.get(`${baseURL}/deviceTypes/${page}`)
    }

    export const fetchCountries = async (page) => {
        return await instance.get(`${baseURL}/countries/${page}`)
    }

    export const fetchAllCountries = async () => {
        return await instance.get(`${baseURL}/allCountries`)
    }

    export const fetchAllGovernments = async () => {
        return await instance.get(`${baseURL}/allGovernments`)
    }

    export const fetchAllIndustrialCities = async () => {
        return await instance.get(`${baseURL}/allIndustrialCities`)
    }

    export const fetchGovernments = async (page) => {
        return await instance.get(`${baseURL}/governments/${page}`)
    }

    export const fetchIndustrialCities = async (page) => {
        return await instance.get(`${baseURL}/industrialCities/${page}`)
    }

    export const fetchIndustrialZones = async (page) => {
        return await instance.get(`${baseURL}/industrialZones/${page}`)
    }

    export const countUsers = async () => {
        return await instance.get(`${baseURL}/countUsers/`)
    }

    export const countDevices = async () => {
        return await instance.get(`${baseURL}/countDevices/`)
    }

    export const countCountries = async () => {
        return await instance.get(`${baseURL}/countCountries/`)
    }

    export const countGovernments = async () => {
        return await instance.get(`${baseURL}/countGovernments/`)
    }

    export const countIndustrialCities = async () => {
        return await instance.get(`${baseURL}/countIndustrialCities/`)
    }

    export const countIndustrialZones = async () => {
        return await instance.get(`${baseURL}/countIndustrialZones/`)
    }

    export const showUser = async (id) => {
        return await instance.get(`${baseURL}/user/${id}`)
    }

    export const showDevice = async (id) => {
        return await instance.get(`${baseURL}/deviceType/${id}`)
    }

    export const showCountry = async (id) => {
        return await instance.get(`${baseURL}/country/${id}`)
    }

    export const showGovernment = async (id) => {
        return await instance.get(`${baseURL}/government/${id}`)
    }

    export const showIndustrialCity = async (id) => {
        return await instance.get(`${baseURL}/industrialCity/${id}`)
    }

    export const showIndustrialZone = async (id) => {
        return await instance.get(`${baseURL}/industrialZone/${id}`)
    }

    export const dataReads = async (user_device_id) => {
        return await instance.get(`${baseURL}/dataReads/${user_device_id}`)
    }

    export const updateUser = async (id,data) => {
        return await instance.put(`${baseURL}/user/${id}`, data)
    }

    export const updateDevice = async (id,data) => {
        return await instance.put(`${baseURL}/deviceType/${id}`, data)
    }

    export const updateCountry = async (id,data) => {
        return await instance.put(`${baseURL}/country/${id}`, data)
    }

    export const updateGovernment = async (id,data) => {
        return await instance.put(`${baseURL}/government/${id}`, data)
    }

    export const updateIndustrialCity = async (id,data) => {
        return await instance.put(`${baseURL}/industrialCity/${id}`, data)
    }

    export const updateIndustrialZone = async (id,data) => {
        return await instance.put(`${baseURL}/industrialZone/${id}`, data)
    }

    export const changePassword = async (data) => {
        return await instance.put(`${baseURL}/changePassword`, data)
    }

    export const deleteUser = async (id) => {
        return await instance.delete(`${baseURL}/user/${id}`)
    }

    export const deleteUserDevice = async (userId,id) => {
        return await instance.delete(`${baseURL}/userDevice/${userId}/${id}`)
    }

    export const deleteDevice = async (id) => {
        return await instance.delete(`${baseURL}/deviceType/${id}`)
    }

    export const deleteCountry = async (id) => {
        return await instance.delete(`${baseURL}/country/${id}`)
    }

    export const deleteGovernment = async (id) => {
        return await instance.delete(`${baseURL}/government/${id}`)
    }

    export const deleteIndustrialCity = async (id) => {
        return await instance.delete(`${baseURL}/industrialCity/${id}`)
    }

    export const deleteIndustrialZone = async (id) => {
        return await instance.delete(`${baseURL}/industrialZone/${id}`)
    }

    export const changeStatus = async (id , status) => {
        return await instance.put(`${baseURL}/user/status/${id}/${status}`)
    }

    export const getAccount = async () => {
        return await instance.get(`${baseURL}/getProfile`)
    }

    export const createAccount = async (data) => {
        return await instance.post(`${baseURL}/createUserAccount`, data)
    }

    export const addDevice = async (data) => {
        return await instance.post(`${baseURL}/deviceType`, data)
    }

    export const addCountry = async (data) => {
        return await instance.post(`${baseURL}/country`, data)
    }

    export const addUserDevice = async (userId , data) => {
        return await instance.post(`${baseURL}/userDevice/${userId}`, data)
    }

    export const addGovernment = async (data) => {
        return await instance.post(`${baseURL}/government`, data)
    }

    export const addIndustrialCity = async (data) => {
        return await instance.post(`${baseURL}/industrialCity`, data)
    }

    export const addIndustrialZone = async (data) => {
        return await instance.post(`${baseURL}/industrialZone`, data)
    }

    export const updateAccount = async (data) => {
        return await instance.put(`${baseURL}/updateUserAccount`, data)
    }
    export const deleteAccount = async () => {
        return await instance.delete(`${baseURL}/deleteUserAccount`)
    }

    export const deleteRead = async (id) => {
        return await instance.delete(`${baseURL}/dataRead/${id}`)
    }