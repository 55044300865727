import React , { useState , useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { dataReads, deleteRead } from '../api/API'

function DataReads(props) {
    const [loading , setLoading] = useState(false)
    const [reads , setReads] = useState([])
    let history = useHistory()
    useEffect(() => {
        const dataReadsAPI = async () => {
            return await dataReads(props.match.params.user_device_id).then(res => setReads(res.data))
        }
        dataReadsAPI()
        setLoading(true)
    } , [props])

    const handleDelete = async (id) => {
        let check = window.confirm('Do you want to delete this read ?')
        if(check)
        await deleteRead(id).then(res => {
            setReads(reads.filter((value) => value.id !== id))
        })
        .catch(e => {})
    }
   
    return (
        <div className="row">
          {
        loading ?
        reads.length > 0 ?
        reads.map((value , key) => {
            var date = new Date(value.created_at);
  var day = date.getDate();
  var month = date.getMonth() + 1;
  var year = date.getFullYear();
            return <div className="col-md-3" key={key}>
            <div className="card" style={{width: '18rem'}}>
            <img src={`http://october.qodeex.com/uploads/${value.img}`} className="card-img-top" alt="..." />
            <div className="card-body">
                <p className="card-text">Read (Text): {value.text_img}</p>
                <p className="card-text">Recorded At: {day + '-' + month + '-' + year}</p>
                <button className="btn btn-danger" onClick={() => handleDelete(value.id)}>Delete</button>
                <button className="btn btn-primary" style={{float:'right' }} onClick={(e) => history.goBack()}>Go Back</button>
            </div>
            </div>
            </div>
        })
        :
        <div>
        <div className="alert alert-danger">No Data Found</div>
        <div style={{ display : 'flex', justifyContent : 'center' , alignItems : 'center' }}>
        <button className="btn btn-primary" style={{ width : '30%' , height : 50, fontSize : 20 }} onClick={(e) => history.goBack()}>Go Back</button>
        </div>
        </div>
        :
        <div></div>
}
</div>
    )
}

export default DataReads
