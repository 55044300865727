import React , { useState } from 'react'
import { addDevice } from '../api/API'

function AddDevice() {
    const [error ,setError] = useState([])
    const [name , setName] = useState({ en : '' , ar : '' })
    const [description , setDescription] = useState({ en : '' , ar : ''})
    const [lang] = useState(localStorage.getItem("lang") || "en")
 
    const handleCreateDevice = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        
        if(!name.en || !name.ar || !description.en || !description.ar)
        setError('Please Fill All Data')
        else{
        formData.append('name' , JSON.stringify(name))
        formData.append('description' , JSON.stringify(description))
        await addDevice(formData).then(res => window.location = "/devices/1").catch(e => setError(e.response.data.errors[0].message))
    }
}

    return (
      <div className="row" style={lang === "ar" ? {direction:'rtl' , textAlign : 'right'} : {direction:'ltr' , textAlign : 'left'}}>
      <div className="col-md-12">
          <div className="main-card mb-3 card">
              <div className="card-body">
                  <h5 className="card-title">{lang === "en" ? "Add Device" : "إضافة جهاز"}</h5>
                  <form id="signupForm" onSubmit={handleCreateDevice} className="col-md-10 mx-auto" method="post">
                      <div className="form-group">
                          <label for="name">{lang === "en" ? "English Name" : "الأسم بالإنجليزية"} *</label>
                          <div>
                              <input type="text" className="form-control" value={name.en} onChange={e => setName({...name , en : e.target.value})} placeholder={lang === "en" ? "English Name" : "الأسم بالإنجليزية"} />
                          </div>
                      </div>
                      <div className="form-group">
                          <label for="name">{lang === "en" ? "Arabic Name" : "الأسم بالعربية"} *</label>
                          <div>
                              <input type="text" className="form-control" value={name.ar} onChange={e => setName({...name , ar : e.target.value})} placeholder={lang === "en" ? "Arabic Name" : "الأسم بالعربية"} />
                          </div>
                      </div>
                      <div className="form-group">
                          <label for="description">{lang === "en" ? "Description Name" : "الوصف بالإنجليزية"} *</label>
                          <div>
                              <textarea className="form-control" value={description.en} onChange={e => setDescription({...description , en : e.target.value})} placeholder={lang === "en" ? "Description Name" : "الوصف بالإنجليزية"}></textarea>
                          </div>
                      </div>
                      <div className="form-group">
                          <label for="description">{lang === "en" ? "Description Name" : "الوصف بالعربية"} *</label>
                          <div>
                              <textarea className="form-control" value={description.ar} onChange={e => setDescription({...description , ar : e.target.value})} placeholder={lang === "en" ? "Description Name" : "الوصف بالعربية"}></textarea>
                          </div>
                      </div>

                      {error.length !== 0 ? <div className="alert alert-danger">{error.length === 24 || error.length === 20 ? error : lang === "en" ? JSON.parse(error).en : JSON.parse(error).ar}</div> : ''}

                      <div className="form-group">
                          <button type="submit" className="btn btn-primary">{lang === "en" ? "Add Device" : "إضافة جهاز"}</button>
                      </div>
                  </form>
              </div>
          </div>
      </div>
  </div>
  )
}

export default AddDevice
