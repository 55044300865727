import React , { useState , useEffect } from 'react'
import { showIndustrialCity } from '../api/API'

function ModalIndustrialCity(props) {
    const [loading , setLoading] = useState(false)
    const [industrialCity , setIndustrialCity] = useState({})
    const [lang] = useState(localStorage.getItem("lang") || "en")

    useEffect(() => {
        showIndustrialCity(props.id).then(res => setIndustrialCity(res.data)).catch(err => {})
        setLoading(true)
    } , [props.id , props.rand])

    return (
        loading && industrialCity.name ?
        <div className="modal fade show" style={{display:'block'}} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{lang === "en" ? JSON.parse(industrialCity.name).en : JSON.parse(industrialCity.name).ar}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setLoading(false)}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body" style={lang === "ar" ? {textAlign : 'right' , direction : 'rtl'} : {textAlign : 'left' , direction : 'ltr'}}>
            <div className={`alert alert-${JSON.parse(industrialCity.name).en ? 'success' : 'danger'}`}><span>{lang === "en" ? "English Name" : "الأسم بالإنجليزية"} : </span><span>{JSON.parse(industrialCity.name).en}</span></div>
            <div className={`alert alert-${JSON.parse(industrialCity.name).ar ? 'success' : 'danger'}`}><span>{lang === "en" ? "Arabic Name" : "الأسم بالعربية"} : </span><span>{JSON.parse(industrialCity.name).ar}</span></div>
            <div className={`alert alert-${JSON.parse(industrialCity.government.name).en ? 'success' : 'danger'}`}><span>{lang === "en" ? "English Government Name" : "أسم المحافظة بالإنجليزية"} : </span><span>{JSON.parse(industrialCity.government.name).en}</span></div>
            <div className={`alert alert-${JSON.parse(industrialCity.government.name).ar ? 'success' : 'danger'}`}><span>{lang === "en" ? "Arabic Government Name" : "أسم المحافظة بالعربية"} : </span><span>{JSON.parse(industrialCity.government.name).ar}</span></div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setLoading(false)} data-dismiss="modal">{lang === "en" ? "Close" : "إغلاق"}</button>
            </div>
        </div>
    </div>
</div>
        : ''
    )
}

export default ModalIndustrialCity
