import React , { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { updateUser , showUser , fetchAllCountries } from '../api/API'
// import EditUser from '../pages/EditUser'

function EditUser(props) {
    const [user , setUser] = useState({})
    let history = useHistory()
    const [username , setUsername] = useState('')
    const [email , setEmail] = useState('')
    const [countryId , setCountryId] = useState(0)
    const [governmentId , setGovernmentId] = useState(0)
    const [industrialCityId , setIndustrialCityId] = useState(0)
    const [industrialZoneId , setIndustrialZoneId] = useState(0)
    const [state , setState] = useState('')
    const [phone , setPhone] = useState('')
    const [address , setAddress] = useState('')
    const [pieceNumber , setPieceNumber] = useState('')
    const [streetName , setStreetName] = useState('')
    const [website , setWebsite] = useState('')
    const [error ,setError] = useState('')
    const [countries , setCountries] = useState([])
    const [lang] = useState(localStorage.getItem("lang") || "en")

    useEffect(() => {
        showUser(props.id).then(res => {
            setUser(res.data)
            setUsername(res.data.username)
            setEmail(res.data.email)
            setCountryId(res.data.country_id)
            setGovernmentId(res.data.government_id)
            setIndustrialCityId(res.data.industrial_city_id)
            setIndustrialZoneId(res.data.industrial_zone_id)
            setState(res.data.state)
            setPhone(res.data.phone)
            setAddress(res.data.address)
            setPieceNumber(res.data.piece_number)
            setStreetName(res.data.street_name)
            setWebsite(res.data.website)
        }).catch(err => err.response.data.check === false ? history.push('/users/1') : '')

        fetchAllCountries().then(res => setCountries(res.data)).catch(err => history.push('/users/1'))

    } , [history , props.id])
    const handleUpdateAccount = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        user.username !== username ? formData.append('username' , username) : <></>
        user.email !== email ? formData.append('email' , email) : <></>
        if (Number(countryId) === 0){
            formData.append('country_id' , 0)
            formData.append('government_id' , 0)
            formData.append('industrial_city_id' , 0)
            formData.append('industrial_zone_id' , 0)
        }
        else if (Number(governmentId) === 0){
            formData.append('country_id' , countryId)
            formData.append('government_id' , 0)
            formData.append('industrial_city_id' , 0)
            formData.append('industrial_zone_id' , 0)
        }
        else if (Number(industrialCityId) === 0){
            formData.append('country_id' , countryId)
            formData.append('government_id' , governmentId)
            formData.append('industrial_city_id' , 0)
            formData.append('industrial_zone_id' , 0)
        }
        else if (Number(industrialZoneId) === 0){
            formData.append('industrial_zone_id' , 0)
            formData.append('country_id' , countryId)
            formData.append('government_id' , governmentId)
            formData.append('industrial_city_id' , industrialCityId)
        }
        else{
            formData.append('country_id' , countryId)
            formData.append('government_id' , governmentId)
            formData.append('industrial_city_id' , industrialCityId)
            formData.append('industrial_zone_id' , industrialZoneId)
        }

        formData.append('state' , state)
        formData.append('phone' , phone)
        formData.append('address' , address)
        formData.append('piece_number' , pieceNumber)
        formData.append('street_name' , streetName)
        formData.append('website' , website)
        await updateUser(props.id , formData).then(res => history.goBack()).catch(e => setError(e.response.data.errors[0].message))
    }

    return (
        <div className="row" style={lang === "ar" ? {direction:'rtl' , textAlign : 'right'} : {direction:'ltr' , textAlign : 'left'}}>
        <div className="col-md-12">
            <div className="main-card mb-3 card">
                <div className="card-body">
                    <h5 className="card-title">{lang === "en" ? "Edit Profile" : "تعديل الملف الشخصى"}</h5>
                    <form id="signupForm" onSubmit={handleUpdateAccount} className="col-md-10 mx-auto" method="post">
                        <div className="form-group">
                            <label htmlFor="username">{lang === "en" ? "Username" : "اسم المستخدم"}</label>
                            <div>
                                <input type="text" disabled className="form-control" value={username} onChange={e => setUsername(e.target.value)} placeholder={lang === "en" ? "Username" : "اسم المستخدم"} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">{lang === "en" ? "Email" : "البريد الإلكترونى"}</label>
                            <div>
                                <input type="email" disabled className="form-control" value={email} onChange={e => setEmail(e.target.value)} placeholder={lang === "en" ? "Email" : "البريد الإلكترونى"} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label for="country">{lang === "en" ? "Choose Country " : "اختر البلد "} *</label>
                            <select className="form-control" onChange={(e) => setCountryId(e.target.value)}>
                            <option value="0" key="0">{lang === "en" ? "Choose Country" : "اختر البلد"}</option>
                                {
                                    countries.map((value , key) => (
                                      <option value={value.id} selected={value.id === countryId ? true : false} key={key}>{lang === "en" ? JSON.parse(value.name).en : JSON.parse(value.name).ar}</option>
                                    ))
                                }
                            </select>
                        </div>
  
                        <div className="form-group">
                            <label for="government">{lang === "en" ? "Choose Government " : "اختر المحافظة "} *</label>
                            <select className="form-control" onChange={(e) => setGovernmentId(e.target.value)}>
                            <option value="0" key="0">{lang === "en" ? "Choose Government" : "اختر المحافظة"}</option>
                                {
                                    countries.map((value , key) => {
                                        return Number(value.id) === Number(countryId)
                                        ?
                                        value.governments.map((value , key) => (
                                          <option value={value.id} selected={value.id === governmentId ? true : false} key={key}>{lang === "en" ? JSON.parse(value.name).en : JSON.parse(value.name).ar}</option>
                                        ))
                                        : <div key={key}></div>
                                    })
                                    
                                }
                            </select>
                        </div>
  
                        <div className="form-group">
                            <label for="government">{lang === "en" ? "Choose Industrial City " : "اختر المدينة الصناعية "} *</label>
                            <select className="form-control" onChange={(e) => setIndustrialCityId(e.target.value)}>
                            <option value="0" key="0">{lang === "en" ? "Choose Industrial City" : "اختر المدينة الصناعية"}</option>
                                {
                                    countries.map((value , key) => {
                                        return Number(value.id) === Number(countryId)
                                        ?
                                        value.governments.map((value , key) => {
                                          return Number(value.id) === Number(governmentId)
                                          ?
                                          value.industrial_cities.map((value , key) => (
                                            <option value={value.id} selected={value.id === industrialCityId ? true : false} key={key}>{lang === "en" ? JSON.parse(value.name).en : JSON.parse(value.name).ar}</option>
                                          ))
                                          : <div key={key}></div>
                                      }) : <div key={key}></div>
                                    })
                                    
                                }
                            </select>
                        </div>
  
  
                        <div className="form-group">
                            <label for="government">{lang === "Choose Industrial Zone " ? "Email" : "اختر المنطقة الصناعية "} *</label>
                            <select className="form-control" onChange={(e) => setIndustrialZoneId(e.target.value)}>
                            <option value="0" key="0">{lang === "en" ? "Choose Industrial Zone" : "اختر المنطقة الصناعية"}</option>
                                {
                                    countries.map((value , key) => {
                                        return Number(value.id) === Number(countryId)
                                        ?
                                        value.governments.map((value , key) => {
                                          return Number(value.id) === Number(governmentId)
                                          ?
                                          value.industrial_cities.map((value , key) => {
                                              return Number(value.id) === Number(industrialCityId)
                                          ?
                                          value.industrial_zones.map((value , key) => (
                                              <option value={value.id} selected={value.id === industrialZoneId ? true : false} key={key}>{lang === "en" ? JSON.parse(value.name).en : JSON.parse(value.name).ar}</option>
                                          ))
                                          : <div key={key}></div>
                                    }) : <div key={key}></div>   
                                }) : <div key={key}></div>
                              })
                          }
                            </select>
                        </div>
  
                        <div className="form-group">
                            <label htmlFor="piece_number">{lang === "en" ? "Piece Number" : "رقم القطعة"}</label>
                            <div>
                                <input type="text" className="form-control" value={pieceNumber} onChange={e => setPieceNumber(e.target.value)} placeholder={lang === "en" ? "Piece Number" : "رقم القطعة"} />
                            </div>
                        </div>
  
                        <div className="form-group">
                            <label htmlFor="street_name">{lang === "en" ? "Street Name" : "اسم الشارع"}</label>
                            <div>
                                <input type="text" className="form-control" value={streetName} onChange={e => setStreetName(e.target.value)} placeholder={lang === "en" ? "Street Name" : "اسم الشارع"} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="state">{lang === "en" ? "State" : "محل الإقامة"}</label>
                            <div>
                                <input type="text" className="form-control" value={state} onChange={e => setState(e.target.value)} placeholder={lang === "en" ? "State" : "محل الإقامة"} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">{lang === "en" ? "Phone" : "رقم الهاتف"}</label>
                            <div>
                                <input type="text" className="form-control" value={phone} onChange={e => setPhone(e.target.value)} placeholder={lang === "en" ? "Phone" : "رقم الهاتف"} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="address">{lang === "en" ? "Address" : "العنوان"}</label>
                            <div>
                                <textarea className="form-control" value={address} onChange={e => setAddress(e.target.value)} placeholder={lang === "en" ? "Address" : "العنوان"}></textarea>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="website">{lang === "en" ? "Website" : "الموقع الإلكترونى"}</label>
                            <div>
                                <input type="text" className="form-control" value={website} onChange={e => setWebsite(e.target.value)} placeholder={lang === "en" ? "Website" : "الموقع الإلكترونى"} />
                            </div>
                        </div>
  
                        {error ? error.length === 29 ? <div className="alert alert-success">{lang === "en" ? error : "تم تعديل الحساب بنجاح"}</div> : <div className="alert alert-danger">{lang === "ar" ? JSON.parse(error).ar : JSON.parse(error).en}</div> : ''}
  
                        <div className="form-group">
                            <button type="submit" className="btn btn-primary">{lang === "en" ? "Edit Profile" : "تعديل الملف الشخصى"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EditUser
