import React , { useState } from 'react'
import { Link } from 'react-router-dom'
import { changePassword } from '../api/API'

function ChangePassword() {

    const [password , setPassword] = useState('')
    const [showPassword , setShowPassword] = useState(false)
    const [error ,setError] = useState('')
    const [lang] = useState(localStorage.getItem("lang") || "en")

    const handleChangePassword = async (e) => {

        e.preventDefault()

        const formData = new FormData()

        formData.append('password' , password)

        await changePassword(formData).then(res => { setError(res.data.message); setPassword('') }).catch(e => setError(e.response.data.errors[0].message))
    
    }

    return (
      <div className="row" style={lang === "ar" ? {direction:'rtl' , textAlign : 'right'} : {direction:'ltr' , textAlign : 'left'}}>
      <div className="col-md-12">
          <div className="main-card mb-3 card">
              <div className="card-body">
                  <h5 className="card-title">{lang === "en" ? "Change Password" : "تغيير كلمة المرور"}</h5>
                  <form id="signupForm" onSubmit={handleChangePassword} className="col-md-10 mx-auto" method="post">
                      <div className="form-group">
                          <label for="username">{lang === "en" ? "Password" : "كلمة المرور"}</label>
                          <div>
                              <input type={ !showPassword ? 'password' : 'text' } className="form-control" value={password} onChange={e => setPassword(e.target.value)} placeholder={lang === "en" ? "Password" : "كلمة المرور"} />
                          </div>
                      </div>

                      {error ? error.length === 30 ? <div className="alert alert-success">{lang === "en" ? error : "تم تغيير كلمة المرور بنجاح"}</div> : <div className="alert alert-danger">{lang === "en" ? JSON.parse(error).en : JSON.parse(error).ar}</div> : ''}

                      <div className="form-group">
                          <button type="submit" className="btn btn-primary mr-3" style={{ margin : 10 }}>{lang === "en" ? "Change Password" : "تغيير كلمة المرور"}</button>
                          <Link to="#" onClick={ () => setShowPassword(!showPassword) } className="btn btn-secondary">{ !showPassword ? lang === "en" ? "Show Password" : "إظهار كلمة المرور" : lang === "en" ? "Hide Password" : "إخفاء كلمة المرور" }</Link>
                      </div>
                  </form>
              </div>
          </div>
      </div>
  </div>
  )
}

export default ChangePassword
